.room{
  display:flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.legend-item{
  margin-left: 10px;
  display:flex;
  align-items: center;
  font-size:14px;
}

.legend-colour{
  display:inline-block;
  width:10px;
  height:10px;
  margin-right:6px;
  border-radius: 10px;
}
