.horiz-scroll{
  width: calc(100%);
  display: flex;
  overflow:auto;
}

.horiz-scroll > div {
 padding: 8px;
 min-width:200px
}
