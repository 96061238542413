.stack-section{
  transition: all 300ms;
}

.legend{
    display:flex;
    justify-content: flex-end;
}

.legend-item{
    margin-left: 10px;
}
.legend-colour{
    display:inline-block;
    width:10px;
    height:10px;
    margin-right:6px;
}
