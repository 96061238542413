nav ul{
  list-style: none;
  margin:0;
  display:flex;
  align-items: center;
  height: 100%;
  padding: 0 10px 0 0;
}

nav ul li {
  color: #616063;
}

nav ul a{
  padding: 22px 15px;
  font-size: 14px;
  text-decoration: none;
  color: #616063;
}

nav ul a.active{
  background-color: #EAEEFF;
}

.profile-popover{
  margin: 20px 20px 10px 10px;
}

.profile-popover .profile-details {
  margin-left: 6px;
}

.profile-popover .profile-welcome {
  margin-bottom: 4px;
}

.profile-popover .profile-name {
  margin-top: 0;
  color: #666;
  margin-bottom: 4px;
}

.profile-popover .profile-email {
  font-size: 0.7rem;
  margin-top: 2px;
}


@media (min-width: 600px) {
  nav ul{
    padding: 0 22px 0 0;
  }
}
