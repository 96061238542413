.lds-grid {
    display: inline-block;
    position: relative;
    width: 82px;
    height: 80px;
}
/*.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: hsl(231,48%,48%);
    animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}
@keyframes lds-grid {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}*/

.loading-container{
  width: 100%;
  top: 50%;
  z-index: 10;
  position: relative;
  text-align:center;

  /* Force the loader to hide for 300ms before showing, stops it popping up constantly */
  visibility: hidden;
  animation: .3s fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

#nexus-img {
  position: relative;
  animation: rotation 3s infinite ease-in-out;
  transform-origin: 50% 41px;
}

@keyframes rotation {
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
