.common-shift-head {
  float:left;
  width:216px;
  height:66px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-transform: uppercase;
}

.common-shifts{
  font-size: 0.78rem;
  background: #fff;
  margin-top: 6px;
  height:66px;
  font-weight: bold;
  border:1px dashed #eee;
  padding: 0 0;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -1px 4px #eee;
}

.common-shifts-outer {
  width: calc(100vw - 400px);
  overflow-x: scroll;
}

.common-shifts-inner {
  width: 3600px;
  overflow-x: hidden;
}

.common-shift{
  padding: 9px 6px;
  width:200px;
  float:left;
}
