.rota {
  font-size: 0.78rem;
  overflow-x: scroll;
  overflow-y: hidden;
}

.rota-dragging{
  box-shadow: 0px 0px 2px 0px #017385;
}

.date-name{
  font-weight: bold;
}

.staff-name{
  font-weight: bold;
}

.date-head{
  text-align: center;
}

.date-head{
  padding: 15px 11px;
  background: #fff;
}

.staff-row-name, .staff-row-shift{
  background: #fff;
  position: relative;
  padding:10px;
}

.holiday{
  border: 1px solid #007385;
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  color: #017385;
}

.staff-row-shift{
 padding:9px 6px;
}

.day-of-week,
.date-grid {
  display: grid;
  grid-template-columns: repeat(8, minmax(100px, 1fr));
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.day-of-week{
  margin-bottom: 2px;
}

.date-grid .add-shift{
  position: absolute;
  transition: all 0.3s .4s;
  opacity: 0;
  text-align: center;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
  font-size: 1.5rem;
}

.date-grid .add-another-shift{
  transition: all 0.3s 1.0s;
  position: absolute;
  bottom: -15px;
  right: -11px;
  top: auto;
  left: auto;
  font-size: 1.25rem;
}

.staff-row-shift:hover .add-shift{
  opacity: 1;
}

.add-shift-popover {
  overflow: hidden;
}

.add-shift-head{
  width: 280px;
  font-size:1.1rem;
  margin-bottom:20px;
}

.add-shift-times{
  margin-bottom:16px;
}
.add-shift-popover .control{
  margin-bottom:16px;
}

.add-shift-foot{
  padding:8px 0 8px 8px;
  text-align: right;
  margin-top:10px
}

.add-shift-foot Button{
  margin-left:12px;
}

.today{
  background: #e8f2e9;
}
