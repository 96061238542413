.horizontalSplit {
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2em;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto 20px 0;
}

.horizontalSplit>span {
  position: relative;
  display: inline-block;
}

.horizontalSplit>span:before, .horizontalSplit>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #dfdfdf;
}

.horizontalSplit>span:before {
  right: 100%;
  margin-right: 60px;
}

.horizontalSplit>span:after {
  left: 100%;
  margin-left: 60px;
}
