.lds-grid {
    display: inline-block;
    position: relative;
    width: 82px;
    height: 80px;
}

.loading-box{
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  z-index: 10;
  position: absolute;
  background: #fff;
  border-radius: inherit;
  border: 1px solid #fff;
  opacity: 0.5;

  /* Force the loader to hide for 300ms before showing, stops it popping up constantly */
  visibility: hidden;
  animation: .3s fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

#nexus-img {
  position: relative;
  animation: rotation 3s infinite ease-in-out;
  transform-origin: 50% 41px;
}

@keyframes rotation {
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
