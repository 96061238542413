*, *::before, *::after {
  box-sizing: border-box;
}

aside {
  display: none;
  padding: 20px;
  background-color: #936;
}

footer {
  grid-column: span 3;
  padding: 30px;
  text-align: center;
  font-size: 12px;
}

.public-form{
  margin: 80px auto 0;
  max-width:360px;
}

.form-group{
  margin-bottom: 28px;
}

.control{
  width: 100%;
}

.prompt{
  margin-bottom: 36px;
}


.head-action {
  float: right;
  text-decoration: none;
}

.bold {
  font-weight:bold;
  color:#333;
}

.heading {
  display:flex;
  height: 50px;
  margin-top: 2rem;
  margin-bottom: 1.3rem;
}

.heading-text {
  flex: 1 0 auto;
  line-height: 50px;
  font-size: 22px;
}

.my-paper{
  padding:20px;
}

.paper{
  padding: 16px;
}

.single-col-form {
  max-width:500px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.blink-me {
  animation: blinker 2s linear infinite;
}

.notice-me {
  animation: blinker 1s linear 3;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}


/** Datepicker overrides **/

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color:#333;
}
