.App {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 0;
}

.main {
  flex: 1 0 auto;
  padding: 10px 16px 10px;
  background: #f7fbfd;
  width: 100%;
  box-sizing: border-box;
  margin:0 auto;
}

.logged-out .main {
  background: transparent;
}

.page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width:100%;
}

.logged-out .side-nav{
  display: none;
}

.box-head{
  text-transform: uppercase;
  font-size:12px;
  text-align: left;
  margin: 0 0 16px 0;
  color:rgba(0, 0, 0, 0.54)
}

@media (min-width: 600px) {
  .main{
    padding: 10px 32px 10px 32px;
  }
}

@media (min-width: 1025px) {
  .main{
    padding: 10px 44px 10px 44px;
    width: calc(100vw - 81px);
  }


}
