.header {
  display: flex;
  height: 80px;
  text-align: left;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  border-bottom: 1px solid #dce0e6;
}

.spacer {
  flex:1 0 auto;
}

.logo {
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  background: rgba(255,255,255,0.1);
  text-transform: lowercase;
  flex: 0 0 auto;
  justify-content: center;
  padding-left:80px;
}

.header .customer {
  font-size: 14px;
  padding-left: 16px;
  color: #616063;
  text-transform: capitalize;
  text-align: right;
}

.header.logged-out .spacer, .header.logged-out nav{
  display: none;
}

.header.logged-out{
  border:0;
  justify-content: center;
}

.header.logged-out .logo{
  border:0;
  max-width: 360px;
  padding:0;
}

.header.logged-out .customer {
  display:none;
}


@media (min-width: 1025px) {
  .spacer{
    display: block;
  }

  .logo {
    display: flex;
    padding: 0 44px;
    text-align: center;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    background: rgba(255,255,255,0.1);
    text-transform: lowercase;
    flex: 0 0 auto;
  }
}
