.horizontalSplit {
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2em;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto 20px 0;
}

.horizontalSplit>span {
  position: relative;
  display: inline-block;
}

.horizontalSplit>span:before, .horizontalSplit>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #dfdfdf;
}

.horizontalSplit>span:before {
  right: 100%;
  margin-right: 60px;
}

.horizontalSplit>span:after {
  left: 100%;
  margin-left: 60px;
}

.social-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.social-btn {
  position: relative;
  width: 100%;
  border: 1px solid #c2c8d0;
  border-radius: 3px;
  font-size: 16px;
  align-items: center;
  background-color: #fff;
  height: 52px;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
  color: #2d333a;
  margin-bottom: 16px;
  display: flex;
  outline: 0;
  padding: 0 8px 0 52px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.social-logo-wrapper {
  position: absolute;
  left: 26px;
  top: 50%;
  transform: translate(-50%) translateY(-50%);
}

.social-logo {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.social-btn:hover {
  background-color: #f5f5f5;
}

.social-text {
  text-align: left;
  position: relative;
}
