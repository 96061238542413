.rh-tooltip {
  background-color: white;
  border: 1px solid #777;
  opacity: 0;
  color: #333;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  border-radius: 6px;
  margin-top:-7px;
  position: absolute;
  padding:5px 6px;
  transition: all 300ms;
  transition-property: top,left;
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4)
}

.rh-arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -7px;
  border: 7px solid transparent;
  border-top-color: #777;
}

.rh-tooltip-date {
  text-align: left;
  font-size: 12px;
}

.rh-tooltip-value {
  white-space: nowrap;
}

.legend-colour{
  display:inline-block;
  width:10px;
  height:10px;
  margin-right:6px;
  border-radius: 10px;
}
